import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Dialog,
  Field,
  List,
  NavBar,
  Picker,
  Popup,
  Radio,
  RadioGroup,
  Row,
  Search,
  Tabbar,
  TabbarItem,
  Toast,
  Uploader,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  Loading,
  ActionSheet,
  Rate,
  Pagination,
  Tab,
  Tabs,
  Badge,
  NoticeBar,
  Icon,
  Sticky,
  Swipe,
  SwipeItem,
  Tag,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  SubmitBar,
  Stepper,
  Card,
  Sku,
  AddressList,
  AddressEdit,
  Area,
} from "vant";

Vue.use(ActionSheet);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(TabbarItem);
Vue.use(Tabbar);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(List);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Loading);
Vue.use(Rate);
Vue.use(Pagination);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Badge);
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Sticky);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tag);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(SubmitBar);
Vue.use(Stepper);
Vue.use(Card);
Vue.use(Sku);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Area);
Toast.allowMultiple();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
